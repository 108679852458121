<template>
  <div ref="element_ref">
    <!--layouts/default.vue-->
    <div class="position-absolute" style="z-index: -1; ">
        <img src="/vstatic/images/Circle_Grey_Solid.svg.png" id="hidden_circle" style="width: 20px;"/>
    </div>
    <span class="hidden_message_attached_to_cursor" style="display:none"></span>
    
    <!-- Header starts -->
    <div class="container-fluid form-inline header_div" >
      <a href="/" class="logo_link" no-prefetch @click.stop.prevent="logo_clicked"><img src="/vstatic/images/logo.png" class="logo"/></a>
      <SearchRunbookTextbox />
      <NuxtLink no-prefetch class="trial-link"
          to='https://dev.dagknows.com/signup'
          v-if="community"
          :disabled="! page_mounted_state"
          :class="{'disabled':  ! page_mounted_state}" 
      >
        <button class="btn btn-outline-primary trial-btn prevent_vertical_expansion" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Sign up for a 14 day trial on your infrastructure">14 day trial</button>
      </NuxtLink>

      <select id="workspace_dropdown" class="form-select" v-if="isLoggedIn" @change="space_dropdown_change" v-show="! community">
        <option :value="space.id" v-for="(space, index) in spaces" :selected="is_selected(space)">
          {{ space.title }}
        </option>
      </select>
      <CreateRunbookBtn/>
      <TopCopilotBtn v-show="isLoggedIn && appIsReady" v-if="(! community)"/>
      <HeaderNotificationBell v-show="isLoggedIn && appIsReady && has_approve_reject_privilege_ref.valueOf() &&  (! is_login_redirect_ref.valueOf())"/>
      <!-- <SignUpBtn v-if="!isLoggedIn" v-show="appIsReady"/> -->
      <SignInBtn v-if="!isLoggedIn" />

      <button class="btn btn-outline-primary trial-btn prevent_vertical_expansion" 
        v-if="(! isLoggedIn)"
        @click="signup"
        >Sign up</button>

      <UserIconTopRight v-show="isLoggedIn && appIsReady  && (! is_login_redirect_ref.valueOf()) " :disabled="! page_mounted_state"/>
    </div>
    <!-- Header ends-->

    <div class="d-flex">
      <div class="left_nav_container"  v-if="(isAuthenticated) && (show_left_nav_ref.valueOf())">
        <LazyLayoutLeftSideNav />
      </div>
      <div class="main_content_container" v-show="page_visible_ref">
        <slot />
      </div>
    </div>
    <span id="hidden_drag_div">
      <span class="step_number_only"></span>
      <span class="task_title"></span>
    </span>

    <div v-if="!isAuthenticated" class="footer-area-bg">
      <Footer />
    </div>

    <vue-cookie-accept-decline
      :debug="false"
      :disableDecline="false"
      :showPostponeButton="false"
      @clicked-accept="not_implemented"
      @clicked-decline="not_implemented"
      @clicked-postpone="not_implemented"
      @removed-cookie="not_implemented"
      @status="not_implemented"
      elementId="myPanel1"
      position="bottom-right"
      ref="myPanel1"
      transitionName="slideFromBottom"
      type="floating"
    >
      <!-- Optional -->
      <template #postponeContent>&times;</template>

      <!-- Optional -->
      <template #message>
        We use cookies to ensure you get the best experience on our website.
        <a href="/privacy-policy" target="_blank">Learn More...</a>
      </template>

      <!-- Optional -->
      <template #declineContent>Opt Out</template>

      <!-- Optional -->
      <template #acceptContent>Got It!</template>
    </vue-cookie-accept-decline>

    <!--Code for a generic Bootstrap dialog starts-->
    <!--
      The goal is to experiment and see if we can avoid having to use dkAlert, dkConfirm, and dkPrompt
      which work fine.  Using teleport the way that I have been using is painful.  I am looking for a 
      better way, but perhaps still using teleport.  Perhaps, we can have a generic modal that contain
      just an empty div with an ID, and we can teleport to this empty DIV, and then show this generic 
      Bootstrap modal.

      The component that use this "generic dialog" must supply the content for the for the dialog.
      Specifically, it must also provide:
        <div style="text-align: center">
            <button class="btn btn-primary ok-btn">OK</button>
            <button class="btn btn-secondary cancel-btn">Cancel</button>
        </div>
      and handle the logic for the OK and Cancel button.  If it does not need the OK button, or the 
      Cancel button, it can omit whatever button it does not need.  The target of the teleport needs to
      be dagknows_generic_dialog_body.  After teleporting, the component needs to trigger this dialog
      (show this dialog) using plain JavaScript.  When the component is done handling the Ok button, 
      or the Cancel button, the component must use plain JavaScript to hide this generic modal.
      The showGenericModal and hideGenericModal functions are implemented for this purpose.
    -->
    <div class="modal modal-md" data-bs-backdrop="false" tabindex="-1" aria-labelledby="Modal Dialog" aria-hidden="true" id="dagknows_generic_dialog">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content collaboration-modal">
                <h5 class="modal-title"></h5>
                <div class="text-end modal-close">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body" id="dagknows_generic_dialog_body"></div>
            </div>
        </div>
    </div>
    <!--Code for a generic Bootstrap dialog ends-->
  </div>
</template>
<script setup>
const not_implemented = (event) => {
  /*
  The vue-cookie-accept-decline ( https://github.com/johndatserakis/vue-cookie-accept-decline ) provides 
  a lot of events ( clicked-accept, clicked-decline, clicked-postpone, removed-cookie, and status ).
  So far, we did not implement these event handler functions yet, and we get warnings in the Developer 
  Tools console.  I am not quite sure what we need to do to handle these events yet.  We will need to 
  create one function for each event.
  */
};
let spaces = [];
let spaces_dict = {};

const userInfoStore = useUserInfoStore();
const page_mounted_state = usePageMountedState();
const page_visible_ref = ref(true);
let user_info = userInfoStore.getUserInfo;
let isAuthenticated = ref(false);
const appIsReady = ref(false);
const authState = useAuthState();

watch(page_mounted_state, async (newVal, oldVal) => {
  // page_visible_ref.value = page_mounted_state.value;
  if (! newVal) { // If the page is not mounted (being unmounted), show the wait page
    await showWaitPage();
  }
});

const env = await getEnv();
let community = env.community || '';
let show_left_nav_ref = ref(false);

if ((community != null) && (community.toLowerCase().trim() == 'true')) {
  community = true;
} else {
  community = false;
}

let space_dropdown_state = useSpaceDropdownState();
const route = useRoute();
const aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
const current_space_cookie = useCookie('current_space', {
  'sameSite': 'lax',
  'expires': aYearFromNow
});

const just_signed_in_cookie = useCookie('just_signed_in', {
  'sameSite': 'lax'
});

if (userInfoStore.getUserInfo.hasOwnProperty('role')) {
  isAuthenticated.value = true;
  authState.value = isAuthenticated.value;

  let userDKRoleStore = useUserDKRolesStore();
  if (userDKRoleStore.getUserDKRoles == null) {
    let dkroles = await getDKRolesForUser(userInfoStore.getUserInfo.uid);
    userDKRoleStore.setUserDKRoles(dkroles);
  }

  let allDKRolesAndPrivilegesStore = useAllDKRolesAndPrivilegesStore();
  if (allDKRolesAndPrivilegesStore.getAllDKRolesAndPrivileges == null) {
    let allDKRolesAndPrivileges = await getAllRolesAndPrivileges();
    allDKRolesAndPrivilegesStore.setAllDKRolesAndPrivileges(allDKRolesAndPrivileges);
  }

  let spaceStore = useSpaceStore();
  if (spaceStore.getSpaces == null) {
    let sp = await getWorkspaces([]);
    spaceStore.setSpaces(sp);
  }
  spaces = JSON.parse(JSON.stringify(spaceStore.getSpaces)); // Here we have to do JSON.parse because elsewhere in this file, we are using unshift which works with regular array, but not a store
  //spaces = await getWorkspaces([]);
  spaces.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });
  spaces.forEach((space) => {
    spaces_dict[space.id] = space.title;
  })

  if (! ['supremo','admin'].includes(user_info.role.toLowerCase())) {
    /*
    For first time user, non-admin, who do not have access to the default space,
    ideally, we want to display the space that he has access to, without the 
    browser temporarily display the "Default" as selected in the space dropdown.
    */
    if (! current_space_cookie.value) {
      let has_selected = false;
      if ((typeof(just_signed_in_cookie.value) != 'undefined') && (just_signed_in_cookie.value == '1')) {
        spaces.forEach((space) => {
          if ((! has_selected) && (hasAccessToSpace(space.id))) {
            has_selected = true;
            current_space_cookie.value = space.id
          }
        });
      }
    }
  }
  
  spaces.unshift({
    "id": "",
    "title": "Default"
  });

  if ((route.query.workspace_id != '') && (typeof(route.query.workspace_id) != 'undefined')) {
    space_dropdown_state.value = route.query.workspace_id;
    current_space_cookie.value = space_dropdown_state.value;
  } else if (current_space_cookie.value) {
    space_dropdown_state.value = current_space_cookie.value;
  }



  if (typeof(window) != 'undefined') {
    window.addEventListener("focus", function(){
      if (document.getElementById('workspace_dropdown')) {
        current_space_cookie.value = space_dropdown_state.value = document.getElementById('workspace_dropdown').value;
      }
    })
  }
  // Community instance, show left navigation bar only for admin/supremo.  Everybody else should be 
  // techrep and should not see the left navigation bar.

  let role = userInfoStore.getUserInfo['role'];
  if ((role != null) && (['admin', 'supremo'].includes(role.toLowerCase()))) {
    show_left_nav_ref.value = true;
  } else {
    if (community) {
      // Techreps on community are not supposed to see the left navigation bar
      show_left_nav_ref.value = false;
    } else {
      // Techrep, for now, cannot see the left navigation bar across the board
      show_left_nav_ref.value = false;
    }
  }
}

const is_selected = (space) => {
  if (current_space_cookie.value == space.id) {
    return true;
  }
}

let is_login_redirect_ref = ref(false);
if (route.path.includes("vLoginRedirect")) {
  is_login_redirect_ref.value = true;
  await generateCSRFToken(true);
} else {
  const userPreferenceStore = useUserPreferenceStore();
  if (userPreferenceStore.getUserPreference == null) {
    let preferences = await getUserPreferences();
    userPreferenceStore.setUserPreference(preferences);
  }
}

const logo_clicked = async (event) => {
  await navigateToWrapper('/');
}

const check_session_state = async () => {
  let isAuthenticated = localStorage.getItem('isAuthenticated');
  if ((isAuthenticated != null) && (isAuthenticated == '1')) {
    let result = await logFetchAJAX(getUrl('/check_session_state'), {
        method: "GET",
    });
    if (result.session_state == 'expired') {
      await signOut();
      window.location.reload();
    } else {
      setTimeout(check_session_state, 5 * 60 * 1000); // I think it is appropriate to do this check every 5 minutes.
    }
  }
}

if (typeof(window) != 'undefined') {
  if (isAuthenticated.value) {
    setTimeout(() => {
      check_session_state();
    }, 5 * 60 * 1000);  // If the page just loaded (the user just signed-in), we do not need to invoke check_session_state immediately.  Trying to reduce the number of API requests on the initial page load
  }
}

const isLoggedIn = computed(() => {
  return isAuthenticated.value
})

if (userInfoStore.getUserInfo.hasOwnProperty('role')) {
  appIsReady.value = true;
}

let element_ref = ref(null);

onBeforeMount(async () => {
  window.addEventListener('beforeunload', async function(){
    pageUnmoutedCode();
  });
})

onMounted(async () => {
  appIsReady.value = true;

  initializeTooltips(element_ref.value);

  let provision_proxy = localStorage.getItem('provision_proxy');
  if ((provision_proxy != null) && (provision_proxy == '1')) {
    setTimeout(() => {
      // We were calling provisionProxy from inside vLoginRedirect but somehow got an error,
      // probably because there we call  window.location.replace.  We move provisionProxy
      // here because provisioning a proxy can potentially take longer time.
      // Hopefully, on the index page, the user will take some time to look at the runbooks
      // and provisionProxy get a chance to complete what it needs to do.
      provisionProxy(user_info.org, user_info.org).catch((err) => console.log(err));
    }, 1);
    localStorageSetItemWrapper("provision_proxy","0");
  }
});

onBeforeUnmount(async () => {
  pageUnmoutedCode();
})


let download_task_id = (env.download_task_id || '').trim();
const signup = (event) => {
  window.location.href = '/signup';
}

const space_dropdown_change = (event) => {
  //current_space_cookie.value = space_dropdown_state.value = document.getElementById('workspace_dropdown').value;
  current_space_cookie.value = document.getElementById('workspace_dropdown').value;
  if ((typeof(just_signed_in_cookie.value) != 'undefined') && (just_signed_in_cookie.value == '1')) {
    just_signed_in_cookie.value = '0';
  }
  window.location.href = '/';
}

let has_approve_reject_privilege_ref = ref(false);
has_approve_reject_privilege_ref.value = hasPrivilege('workspace.approve_publish', space_dropdown_state.value);

watch(space_dropdown_state, (newVal, oldVal) => {
});


</script>

<style scoped>
.footer-area-bg{
  background-color: #F3F3F3;
  border-top: 1px solid #ccc;
  bottom: 0px;
  z-index: 22;
}
.header_div {
  /* padding-top: 10px; */
  /* padding-bottom: 10px; */
  padding-left: 250px;
  padding-right: 5px;
  text-align: right;
  display: flex;
  position: fixed;
  z-index: 1001;
  border-bottom: 1px solid #ccc;
  background-color: white;
}

.header_div .logo {
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 10px;
  width: 170px;
}
.header_div button,
.header_div a {
  margin: 0px 10px;
}

.main_content_container {
  margin-right: 0px !important;
  padding-top: 48px;
  width: 100%;
  /* I don't know why but setting overflow:auto here seems to keep long line of text inside the code element 
  from breaking the layout of the page
  */
  overflow: auto;
}

.logo_link {
  cursor: pointer;
}

.left-side-nav-wrap {
  position: relative;
  width: 90px;
}

.left_nav_container {
  width: 52px;
}

.prevent_vertical_expansion {
    align-self: center;
}

.trial-btn:hover {
    border: 1px solid blue;
}

.header_div .trial-btn {
  margin: 0px 5px !important;

}
#workspace_dropdown {
  width: auto !important;
  align-self: center;
  margin: 0px 10px;
}
</style>
